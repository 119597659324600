import React from "react";
import { Helmet } from "react-helmet";

import Header from "../../components/Header";
import WelcomeImage1 from "../../components/WelcomeImage1";
import ImageText4 from "../../components/ImageText4";
import ImageText5 from "../../components/ImageText5";
import ImageText6 from "../../components/ImageText6";
import ImageText2 from "../../components/ImageText2";
import ImageText1 from "../../components/ImageText1";
import Footer from "../../components/Footer";
import CardImage from "./CardImage";
import Contact from "./Contact";

import backgroundImage from "../../assets/images/Klus & Tuin Background Image.png";
import imageUrl1 from "../../assets/images/Klus & Tuein 2.png";
import imageUrl2 from "../../assets/images/Klus & Tuein 1.png";
import imageUrl3 from "../../assets/images/Klus & Tuein 3.png";
import imageUrl4 from "../../assets/images/Klus & Tuein 5.png";
import imageUrl5 from "../../assets/images/Klus & Tuein 4.png";
import imageUrl6 from "../../assets/images/Klus & Tuein 6.png";
import imageUrl7 from "../../assets/images/Klus & Tuein 8.png";
import imageUrl8 from "../../assets/images/Klus & Tuein 7.png";
import imageUrl9 from "../../assets/images/Klus & Tuein 9.png";
import src from "../../assets/images/Klus & Tuein 10.png";
import imageUrl10 from "../../assets/images/Klus & Tuein 11.png";
import filedown from "../../assets/files/240501-VLOK-Uitvoeringsvoorwaarden-2024-huisstijl.pdf";

function KlusTuin() {
  const welcomeHeading = <>Klus & Tuin</>;

  

  const imageTextText = (
    <>
      Al jarenlang detacheren en plaatsen wij als DD-Group specialisten in
      diverse sectoren. Variërend van Industrie, Infra & Bouw, Scheepvaart tot
      aan de sector Inkoop & Logistiek. Als detacherings- en werving & selectie
      bureau plaatsen wij onze vakmensen bij opdrachten en projecten in het
      bedrijfsleven. Nu is het al zo dat er op vele vakgebieden grote tekorten
      zijn aan specialisten in het bedrijfsleven en dat de overheid vele
      initiatieven ontwikkeld om jonge mensen te motiveren om een opleiding te
      gaan volgen in de techniek, zorg en onderwijs maar dat is verreweg niet
      voldoende om het gat te dichten.
    </>
  );
  const imageTextText1 = (
    <>
      Niet zo lang geleden in een overleg bespraken wij deze problematiek maar
      dan niet zozeer binnen het bedrijfsleven maar meer vanuit de particuliere
      hoek. Steeds vaker komt de vraag binnen familie en vrienden naar ons toe
      of we niets kunnen betekenen voor particulieren. Iedereen kent het wel,
      dat een bekende zijn of haar tuin wil laten onderhouden, een keuken wil
      laten plaatsen of een pad opnieuw wil laten bestraten maar dat er
      torenhoge tarieven worden gevraagd. Vaak kan er pas na weken zo niet
      maanden later begonnen worden met de klus, en als dan gevraagd wordt of
      het sneller kan dan gaan de prijzen helemaal omhoog. Natuurlijk wordt dit
      ook gevoed door het tekort aan beschikbare vakmensen en zitten de agenda's
      van de ondernemers die deze klussen aannemen helemaal vol.
    </>
  );
  const imageTextText2 = (
    <>
      Het duurde na dit overleg niet lang totdat bij ons het kwartje viel! Wat
      nou als wij de vakspecialisten die wij hebben ook gaan inzetten voor
      diverse klussen bij particulieren. Wij beschikken over een grote database
      van timmerlieden, stratenmakers, stukadoors tot aan grondwerkers. Door dit
      grote aanbod aan specialisten kunnen wij snel schakelen en hoeft het niet
      weken of maanden te duren voordat er aan uw klus kan worden begonnen. Onze
      insteek is ook, juist omdat het de particuliere markt betreft, de tarieven
      op een normaal betaalbaar niveau te houden. De nieuwe dienst "DD-Group
      Klus & Tuin" is begonnen en U kunt vanaf heden uw aanvragen indienen via
      ons contact formulier. De procedure van aanvraag tot voltooiing van de
      klus staat hieronder in heldere stappen beschreven.
    </>
  );
  const imageTextText3 = (
    <>
      Als u op de "Button" Stap 1 Klus aanvragen klikt dan komt u op het
      aanvraagformulier. Als u het formulier heeft ingevuld en eventuele
      informatie heeft opgeladen, klikt u dan op versturen. Wij ontvangen dan
      direct u aanvraag in ons systeem. Wij nemen na ontvangst zo spoedig
      mogelijk contact met u op. De overige stappen zijn voor uw informatie en
      uitleg van onze werkwijze.
    </>
  );
  const imageTextText4 = (
    <>
      DD Klus & Tuin is “lid VLOK, Branchevereniging voor Vakmensen”. Alle
      VLOK-leden zijn aangesloten Bij de Geschillencommissie Klussen- en
      Vloerenbedrijven. Deze onafhankelijke geschillencommissie is tot stand
      gekomen. Op initiatief van de VLOK in samenwerking met de Consumentenbond
      en de Vereniging Eigen Huis. Bij eventuele geschillen zal worden uitgegaan
      van de door VLOK ontwikkelde uitvoeringsvoorwaarden voor vakmensen 2024.
      De voorwaarden vindt U op deze pagina.
    </>
  );
  const buttonText = "Algemene Voorwaarden";
  return (
    <div>
      <Helmet>
        <title>Werving & Selectie</title>
        <meta
          name="description"
          content="DD-Group biedt gespecialiseerde wervings- en detacheringdiensten in verschillende sectoren, waaronder industrie, bouw en logistiek. Wij bieden nu ook 'DD-Group Klus & Tuin' voor particulieren, waarmee je vakbekwame professionals kunt vinden voor thuisprojecten tegen betaalbare tarieven."
        />
      </Helmet>
      <header>
        <Header />
      </header>
      <main>
        <WelcomeImage1
          backgroundImage={backgroundImage}
          heading={welcomeHeading}
        />
        <section>
          <ImageText4
            imageUrl1={imageUrl1}
            imageUrl2={imageUrl2}
            text={imageTextText}
            backgroundColor="#D9D9D9"
            textColor="black"
          />
          <ImageText5
            imageUrl1={imageUrl3}
            imageUrl2={imageUrl4}
            imageUrl3={imageUrl5}
            text={imageTextText1}
          />
          <ImageText6
            imageUrl1={imageUrl6}
            imageUrl2={imageUrl7}
            imageUrl3={imageUrl8}
            text={imageTextText2}
            backgroundColor="#D9D9D9"
            textColor="black"
          />
          <ImageText2 imageUrl={imageUrl9} text={imageTextText3} />
          <ImageText1
            imageUrl={imageUrl10}
            text={imageTextText4}
            backgroundColor="#D9D9D9"
            textColor="black"
            buttonText={buttonText}
            openInNewTab={true}
            downloadFile={filedown}
          />
          <CardImage src={src} />
          <Contact />
        </section>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default KlusTuin;
