import React from "react";
import { Helmet } from "react-helmet";

import Header from "../../components/Header";
import WelcomeImage from "../../components/WelcomeImage";
import ImageText1 from "../../components/ImageText1";
import ImageText2 from "../../components/ImageText2";
import Footer from "../../components/Footer";

import backgroundImage from "../../assets/images/De Ploeg op de Root Background Image.png";
import imageUrl from "../../assets/images/De Ploeg op de Root 1.png";
import imageUrl2 from "../../assets/images/De Ploeg op de Root 2.png";

function DePloegopdeRoot() {
  const welcomeHeading = "DE PLOEG OP DE ROOT";

  const imageTextText = (
    <>
      De DD-Group heeft de ambitie om een belangrijke speler te worden binnen de
      uitzendbranche en recruitment maar wel op een verantwoordelijke en gezonde
      manier. Een belangrijk onderdeel van deze ambitie is zichtbaarheid en
      aanwezigheid op beurzen, social media, netwerkbijeenkomsten etc.. Binnen
      deze ambitie van hebben wij in 2022 een geweldige kans gekregen om
      hoofdsponsor te worden van het Isorex-No Aqua Ladies Cycling Team. Na een
      bewogen jaar zijn de ambities versterkt met een uitbreiding van de ploeg
      en het aantrekken van twee grote nieuwe sponsors.
    </>
  );

  const imageTextHeading2 = "ONZE AMBITIES VOOR 2025: EEN NIEUWE STAP VOORWAARTS"; 
  const imageTextText2 = (
    <>
      In 2025 zetten we een belangrijke stap in onze ontwikkeling met de oprichting van ons professioneel UCI CONTI
      team, bestaande uit 8 belofterensters en 8 eliterensters.
      <br /><br />
      Deze ploeg, zonder taalbarrière, zal volledig bestaan uit Belgische en Nederlandse rensters. Dit vormt een
      uitdaging, maar biedt op lange termijn aanzienlijke voordelen: perfecte communicatie en naadloze planning.
      We hebben de beste talenten uit België en Nederland aangetrokken om deel uit te maken van onze Pro
      Continentale UCI ploeg.
      <br /><br />
      Ons clubteam heeft de afgelopen jaren al aanzienlijke groei doorgemaakt, met mooie resultaten en zelfs enkele
      overwinningen in diverse wedstrijden.
      <br /><br />
      Wat ons onderscheidt, is onze focus op samenwerking en langetermijnsucces.
      We zijn vastberaden om een toonaangevend team in het vrouwenwielrennen te worden, met een unieke aanpak
      die duurzame resultaten oplevert.
    </>
  );

  const backgroundColor = "#D9D9D9";
  const textColor = "black";

  return (
    <div>
      <Helmet>
        <title>Ploeg Op De Root</title>
        <meta
          name="description"
          content="Bij DD-Group zijn we toegewijd aan het bevorderen van de wervingsindustrie met ethische praktijken. Lees over onze samenwerking met het Isorex-No Aqua Dameswielerteam en hoe onze uitgebreide database je kan helpen de ideale kandidaat voor jouw behoeften te vinden."
        />
      </Helmet>
      <header>
        <Header />
      </header>
      <main>
        <section>
          <WelcomeImage
            backgroundImage={backgroundImage}
            heading={welcomeHeading}
          />
          <ImageText1 imageUrl={imageUrl} text={imageTextText} />
          <ImageText2
            imageUrl={imageUrl2}
            heading={imageTextHeading2}
            text={imageTextText2}
            backgroundColor={backgroundColor}
            textColor={textColor}
          />
        </section>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default DePloegopdeRoot;
